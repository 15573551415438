import React from 'react'
import { ContainerBox } from '@components/container'

import { PostLinkBox, PostWrapFlex, StyledPostLink } from './style'
import { PrismicImage } from '@models/image.models'
import { GatsbyImage } from 'gatsby-plugin-image'

interface PostLink {
  type: 'next' | 'previous'
  url: string
  title: string
  feature_image: PrismicImage
}

export const NextPostLinkSlice: React.FC<any> = ({ props }) => {
  let items: PostLink[] = []
  props?.prev
    ? items.push({
        type: 'previous',
        url: props.prev.url,
        title: props.prev.data.post_title.text,
        feature_image: props.prev.data.feature_image,
      })
    : null

    props?.next
    ? items.push({
        type: 'next',
        url: props.next.url,
        title: props.next.data.post_title.text,
        feature_image: props.next.data.feature_image,
      })
    : null

  return (
    <ContainerBox>
      <PostWrapFlex className={`post-layout_${items?.length}`} flexWrap="wrap">
        {items.map((item, index) => (
            <PostLinkBox
              className={`post-item-${item?.type}`}
              width={[1, 1, 1, 1 / 2]}
              key={`${item?.type}-link-${index}`}
            >
              <StyledPostLink to={item.url}>
                <h6>{item.type}</h6>
                {item.type === 'previous' && (
                  <GatsbyImage
                    className="project-icon"
                    image={item?.feature_image.gatsbyImageData}
                    alt="project-icon icon"
                  />
                )}

                <span>{item.title}</span>

                {item.type === 'next' && (
                  <GatsbyImage
                    className="project-icon"
                    image={item?.feature_image.gatsbyImageData}
                    alt="project-icon icon"
                  />
                )}
              </StyledPostLink>
            </PostLinkBox>
        ))}
      </PostWrapFlex>
    </ContainerBox>
  )
}
