import React from 'react'
import { graphql, Link } from 'gatsby'
import { Layout } from '@components/layout'
import { Slice } from '@components/slice'
import { RichText } from 'prismic-reactjs'
import NextPostLinkSlice from '@components/next-post-link'

type Props = {
  data: any
  pageContext: any
}

const WorkshopsPost: React.FC<Props> = props => {
  const { meta, header, seo, main, footer } = props.data
  const slices = main?.data?.body
  // const contentElement = (
  //   <StyledContainerBox as="section">
  //     <TrailFadeUp>
  //       {relationship?.uid && (
  //         <Box mb={'1.5rem'}>
  //           <WorkstyledLink to={relationship?.url}>
  //             {relationship.uid}
  //           </WorkstyledLink>
  //         </Box>
  //       )}
  //       <TitleBox mb={'3.5rem'}>
  //         <RichText render={title.raw} />
  //       </TitleBox>

  //       <ContentBox>
  //         <Flex flexWrap="wrap">
  //           <DescriptionBox width={[1, 1, 1, 3 / 5]} pr="2rem">
  //             <RichText render={summary?.raw} />
  //           </DescriptionBox>
  //           <DescriptionBox width={[1, 1, 1, 2 / 5]} pl={['0', , , '2rem']}>
  //             <DetailsList>
  //               {details.map((item, index) => (
  //                 <li key={`detail-${index}`}>
  //                   <p>
  //                     <span>{item.label}</span>
  //                     <span>{item.value}</span>
  //                   </p>
  //                 </li>
  //               ))}
  //             </DetailsList>
  //           </DescriptionBox>
  //         </Flex>
  //       </ContentBox>
  //     </TrailFadeUp>
  //   </StyledContainerBox>
  // )



  return (
    <Layout
      seo={seo}
      header={header}
      footer={footer}
      // theme={theme}
      activeMeta={meta}
    >
      {/* <RichText render={main.data.post_title?.richText} /> */}
      {/* {contentElement} */}
      <Slice allSlices={slices} pageContext={props.pageContext} />
      <NextPostLinkSlice props={props.pageContext} />
    </Layout>
  )
}

export default WorkshopsPost

export const pageQuery = graphql`
  query WorkshopsPost($uid: String, $locale: String) {
    meta: prismicWorkshopsPost(lang: { eq: $locale }) {
      url
      lang
      type
      alternate_languages {
        uid
        lang
      }
    }

    seo: prismicWorkshopsPost(uid: { eq: $uid }, lang: { eq: $locale }) {
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url(imgixParams: { width: 1200, height: 630 })
        }
      }
    }

    main: prismicWorkshopsPost(uid: { eq: $uid }, lang: { eq: $locale }) {
      data {
        date(formatString: "MMMM YYYY")
        post_title {
          richText
        }
        relationship {
          url
          uid
        }
        body {
          ... on PrismicWorkshopsPostDataBodyFullscreenImage {
            ...fullscreenImageWorkshopsPost
          }
          ... on PrismicWorkshopsPostDataBodyContent {
            ...contentWorkshopsPost
          }
          ... on PrismicWorkshopsPostDataBodyContentWithImage {
            ...contentWithImageWorkshopsPost
          }
          ... on PrismicWorkshopsPostDataBodyGallery {
            ...galleryWorkshopsPost
          }
          ... on PrismicWorkshopsPostDataBodyTitle {
            ...titleWorkshopsPost
          }
        }
      }
    }

    header: prismicMenu(lang: { eq: $locale }) {
      ...header
    }

    footer: prismicFooter(lang: { eq: $locale }) {
      ...footer
    }
  }
`
