import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/grid'
import { colors } from '@styles/colors'
import { Link } from 'gatsby'

export const PostWrapFlex = styled(Flex)`
  .project-icon {
    height: 50px;
    max-width: 50px;
    display: inline-flex;
    img {
      object-fit: contain;
    }
  }

    .post-item-previous, .post-item-next {
      h6 {

        color: ${colors.light};
      }
            position: relative;

      span {
        display: inline-block;
        margin-right: 1rem;

      }
    }

  .post-item-previous {
      span {
        margin-left: 1rem;
        max-width: 68%;
      }

    &:before {
      position: absolute;
      top: 55%;
      left: 25px;
      content: '←';
      padding-right: 0.5rem;
      visibility: hidden;
      color: white;
      z-index: 99;
    }

    &:hover {
      &:before {
        visibility: visible;
      }
    }
  }

  .post-item-next {
      span {
        margin-right: 1rem;
        max-width: 68%;
      }

    &:after {
      position: absolute;
      top: 55%;
      right: 25px;
      content: '→';
      padding-left: 0.5rem;
      visibility: hidden;
      color: white;
      z-index: 99;
    }

    &:hover {

      &:after {
        visibility: visible;
      }
    }
  }

  &.post-layout_1 {
    display: grid;
    grid-template-columns: 1fr;

    .post-item-previous {
      width: 50%;
      text-align: left;
      justify-self: start;

      span {
        display: inline-block;
        margin-left: 1rem;
      }
    }

    .post-item-next {
      width: 50%;
      text-align: right;
      justify-self: end;
    }
  }

  &.post-layout_2 {
    .post-item-previous {
      width: 50%;
      text-align: left;
      justify-self: start;
    }

    .post-item-next {
      width: 50%;
      text-align: right;
      justify-self: end;
    }
  }
}
`

export const PostLinkBox = styled(Box)`
  cursor: pointer;

  h6 {
    color: #6b6a6a;
  }
`

export const StyledPostLink = styled(Link)``
